import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.appointmentsCalendarChannel = this.application.consumer.subscriptions.create({ channel: 'AppointmentsCalendarChannel' })
    this.eventsCalendarChannel = this.application.consumer.subscriptions.create({ channel: 'EventsCalendarChannel' })
  }

  disconnect () {
    this.appointmentsCalendarChannel.unsubscribe()
    this.eventsCalendarChannel.unsubscribe()
  }

  perform () {
    this.appointmentsCalendarChannel.perform('refresh')
    this.eventsCalendarChannel.perform('refresh')
  }
}
