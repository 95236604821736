import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pill-selector"
export default class extends Controller {
  static targets = ['item']

  toggle (event) {
    const navLinkTarget = event.target

    this.itemTargets.forEach(item => {
      item.querySelector('.nav-link').classList.remove('active')
    })

    navLinkTarget.classList.toggle('active')
  }
}
