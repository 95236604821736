import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.form = document.getElementById('appointment-form')
    this.step = this.form.dataset.step
    this.skipSubmitCallback = false

    this.formElements = []
    this.initFormElements()
    this.initFormChangeDetection()

    this.boundHandleInputChange = this.handleInputChange.bind(this)
    this.form.addEventListener('input', this.boundHandleInputChange)
  }

  prefillTreatments (event) {
    event.preventDefault()

    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrfToken
    }

    const treatmentsElement = document.getElementById('treatments-list')
    const petId = document.getElementById('form_pet_id').value
    const url = new URL(`/pets/${petId}/prefill_treatments`, window.location.href)

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        const template = document.createElement('template')
        template.innerHTML = html
        treatmentsElement.append(template.content)
      })
  }

  initFormElements () {
    // element types we want to skip
    const formSkipElementTypes = ['hidden', 'submit']

    for (const [, el] of Object.entries(this.form)) {
      if (formSkipElementTypes.includes(el.type)) {
        continue
      } else {
        this.formElements.push(el)
      }
    }
  }

  initFormChangeDetection () {
    Array.from(this.formElements).forEach(this.formElementSetOrigValue)
  }

  formElementSetOrigValue (el) {
    if (el.type === 'checkbox') {
      el.dataset.origValue = el.checked
    } else {
      el.dataset.origValue = el.value
    }
  }

  formElementChanged (el, index, array) {
    if (el.type === 'checkbox') {
      const value = el.dataset.origValue === 'true'

      return value !== el.checked
    } else {
      return el.dataset.origValue !== el.value
    }
  }

  formHasChanges () {
    return Array.from(this.formElements).some(this.formElementChanged)
  }

  handleInputChange () {
    if (this.formHasChanges()) {
      if (this.step === 'one') {
        if (this.recurringInForm()) {
          this.setNextSubmitButton()
        } else {
          this.setSaveSubmitButton()
        }
      } else {
        this.setSaveSubmitButton()
      }
    } else {
      this.setSaveSubmitButton()
    }
  }

  setNextSubmitButton () {
    this.skipSubmitCallback = true

    const submitInput = this.form.querySelector('input[type=submit]')

    submitInput.value = 'Next'
    submitInput.dataset.disableWith = 'Next'
  }

  setSaveSubmitButton () {
    this.skipSubmitCallback = false

    const submitInput = this.form.querySelector('input[type=submit]')

    submitInput.value = 'Save'
    submitInput.dataset.disableWith = 'Save'
  }

  recurringInForm () {
    const element = document.getElementById('form_recurring')

    return element.checked
  }

  submitEnd (event) {
    if (event.detail.success) {
      if (this.skipSubmitCallback) {
        return
      }

      document.dispatchEvent(new CustomEvent('modal:hide'))
    }
  }
}
