import TurboStreamController from './turbo_stream_controller'
import * as bootstrap from 'bootstrap'

export default class extends TurboStreamController {
  connect () {
    this.modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('mainModal'))
    this.modal._element.focus()

    this.boundShow = this.show.bind(this)
    document.addEventListener('modal:show', this.boundShow)

    this.boundHide = this.hide.bind(this)
    document.addEventListener('modal:hide', this.boundHide)

    this.boundHideForScreenLock = this.hideForScreenLock.bind(this)
    document.addEventListener('modal:hide_for_screen_lock', this.boundHideForScreenLock)

    this.boundModalShow = this.modalShow.bind(this)
    this.modal._element.addEventListener('show.bs.modal', this.boundModalShow)
  }

  disconnect () {
    document.removeEventListener('modal:show', this.boundShow)
    document.removeEventListener('modal:hide', this.boundHide)
    document.removeEventListener('modal:hide_for_screen_lock', this.boundHideForScreenLock)

    this.modal.dispose()
  }

  modalShow (event) {
    if (event.relatedTarget && event.relatedTarget.href) {
      this.renderTurboStream(event.relatedTarget.href)
    }
  }

  show () {
    this.modal.show()
  }

  hide () {
    this.modal.hide()
    this.clearModalElement()
  }

  hideForScreenLock () {
    this.modal.hide()
  }

  submitEndHide (event) {
    if (event.detail.success) {
      document.dispatchEvent(new CustomEvent('modal:hide'))
    }
  }

  submitEndReload (event) {
    if (event.detail.success) {
      document.dispatchEvent(new CustomEvent('modal:hide'))

      window.location.reload(true)
    }
  }

  submitEnd (event) {
    const form = document.getElementById('appointment-form')

    if (event.detail.success) {
      document.dispatchEvent(new CustomEvent('modal:hide'))

      if (form.dataset.initiatedFrom === 'index') {
        window.location.reload(true)
      }
    }
  }

  clearModalElement() {
    this.modal._element.querySelector('#modal-form').innerHTML = ''
  }
}
