import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  renderTurboStream (url) {
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const headers = {
      Accept: 'text/vnd.turbo-stream.html',
      'X-CSRF-Token': csrfToken
    }

    return fetch(url, { headers })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
