import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  amountChanged (event) {
    if (event.target.dataset.new !== 'true') {
      return
    }

    let alreadyAssigned = false
    const pricePerVisitValue = document.getElementById('form_price_per_visit').value
    const paymentItems = ['form_items_1', 'form_items_2']

    paymentItems.forEach((el) => {
      if (alreadyAssigned === true) {
        return
      }

      const formElement = document.getElementById(el)

      if (formElement === null) {
        return
      }

      formElement.value = pricePerVisitValue

      alreadyAssigned = true
    })
  }

  submitEnd (event) {
    if (event.detail.success) {
      document.dispatchEvent(new CustomEvent('modal:hide'))

      window.location.reload(true)
    }
  }
}
