import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const password = document.querySelector('#user_password')

    this.togglePasswordElement = document.querySelector('#togglePassword')
    this.togglePasswordIcon = () => {
      const type = password.getAttribute('type') === 'password' ? 'text' : 'password'
      password.setAttribute('type', type)
      this.togglePasswordElement.classList.toggle('bi-eye')
    }
    this.togglePasswordListener = this.togglePasswordElement.addEventListener('click', this.togglePasswordIcon)
  }

  disconnect () {
    this.togglePasswordElement.removeEventListener('click', this.togglePasswordListener)
  }
}
