import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'toggleLabel', 'toggleIcon']
  static classes = ['hidden']
  static values = { hidden: { type: Boolean, default: false } }

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
  }

  toggle () {
    if (this.hasToggleLabelTarget && this.hasToggleIconTarget) {
      if (this.hiddenValue) {
        this.toggleLabelTarget.textContent = 'Less'
        this.toggleLabelTarget.classList.remove('text-black-50')
        this.toggleIconTarget.classList.remove('bi-arrow-down')
        this.toggleIconTarget.classList.add('bi-arrow-up')
        this.toggleIconTarget.classList.remove('text-black-50')
      } else {
        this.toggleLabelTarget.textContent = 'More'
        this.toggleLabelTarget.classList.add('text-black-50')
        this.toggleIconTarget.classList.remove('bi-arrow-up')
        this.toggleIconTarget.classList.add('bi-arrow-down')
        this.toggleIconTarget.classList.add('text-black-50')
      }
    }

    this.hiddenValue = !this.hiddenValue

    this.itemTargets.forEach(item => { item.classList.toggle(this.class) })
  }

  toggleSection (event) {
    const sectionId = event.params.id
    const sectionElement = document.getElementById(sectionId)

    if (!sectionElement) return
    sectionElement.classList.toggle(this.class)
  }
}
