import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  absentAppointment (event) {
    event.preventDefault()

    const cardFooterElement = event.target.parentElement.parentElement
    const spinnerElement = cardFooterElement.querySelector('#spinner')
    const linksElement = cardFooterElement.querySelector('#links')

    linksElement.classList.add('d-none')
    spinnerElement.classList.toggle('d-none')

    Rails.ajax({
      type: 'PUT',
      url: event.target.href,
      success: (data) => {
        window.location.reload(true)
      }
    })
  }

  cancelAppointment (event) {
    event.preventDefault()

    const cardFooterElement = event.target.parentElement.parentElement
    const spinnerElement = cardFooterElement.querySelector('#spinner')
    const linksElement = cardFooterElement.querySelector('#links')

    linksElement.classList.add('d-none')
    spinnerElement.classList.toggle('d-none')

    Rails.ajax({
      type: 'PUT',
      url: event.target.href,
      success: (data) => {
        window.location.reload(true)
      }
    })
  }
}
