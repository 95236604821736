import TurboStreamController from './turbo_stream_controller'
import throttle from '../utils/throttle'

export default class extends TurboStreamController {
  connect () {
    this.lockAfter = 60 * 1000

    this.screenLockTimerChannel = new BroadcastChannel('screen_lock_timer_channel')
    this.screenLockTimerChannel.onmessage = () => {
      this.resetTimer()
    }

    this.boundSetTimer = this.setTimer.bind(this)
    document.addEventListener('screen_lock:set_timer', this.boundSetTimer)

    this.boundClearTimer = this.clearTimer.bind(this)
    document.addEventListener('screen_lock:clear_timer', this.boundClearTimer)

    this.boundResetTimerOnEvent = throttle(this.resetTimerOnEvent.bind(this), 2000)
  }

  disconnect () {
    this.clearTimer()
    this.screenLockTimerChannel.close()
    this.screenLockTimerChannel = null

    document.removeEventListener('screen_lock:set_timer', this.boundSetTimer)
    document.removeEventListener('screen_lock:clear_timer', this.boundClearTimer)
  }

  setTimer () {
    // Bind timer reset to other events
    document.addEventListener('keydown', this.boundResetTimerOnEvent)
    document.addEventListener('mousemove', this.boundResetTimerOnEvent)
    document.addEventListener('wheel', this.boundResetTimerOnEvent)
    document.addEventListener('click', this.boundResetTimerOnEvent)

    this.timer = setTimeout(function () {
      document.dispatchEvent(new CustomEvent('screen_lock:show'))
    }, this.lockAfter)
  }

  clearTimer () {
    document.removeEventListener('keydown', this.boundResetTimerOnEvent)
    document.removeEventListener('mousemove', this.boundResetTimerOnEvent)
    document.removeEventListener('wheel', this.boundResetTimerOnEvent)
    document.removeEventListener('click', this.boundResetTimerOnEvent)

    clearTimeout(this.timer)
  }

  resetTimer () {
    this.clearTimer()
    this.setTimer()
  }

  resetTimerOnEvent () {
    this.resetTimer()
    this.screenLockTimerChannel.postMessage('refresh_screen_lock')
  }
}
