import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.boundAutocomplete = this.autocomplete.bind(this)

    document.addEventListener('autocomplete.change', this.boundAutocomplete)
  }

  disconnect () {
    document.removeEventListener('autocomplete.change', this.boundAutocomplete)
  }

  autocomplete (event) {
    if (event.target.classList.contains('form_rehabilitation_plan_treatments')) {
      this.addToList(event.detail)
      this.clearSearchForm()
    }
  }

  clearSearchForm () {
    document.getElementById('form_rehabilitation_plan_treatments_search').value = ''
    document.getElementById('form_rehabilitation_plan_treatments').value = ''
  }

  listElementUrl (detail) {
    return new URL(`/treatments/${detail.value}/examinations_rehabilitation_plans_list_element`, window.location.href)
  }

  addToList (detail) {
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const headers = { 'X-Requested-With': 'XMLHttpRequest', 'X-CSRF-Token': csrfToken }
    const treatmentsListElement = document.getElementById('treatments-list')
    const treatmentsChanged = document.getElementById('form_rehabilitation_plan_treatments_changed')
    const url = this.listElementUrl(detail)

    if (treatmentsChanged !== null) {
      treatmentsChanged.value = 1
    }

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        const template = document.createElement('template')
        template.innerHTML = html
        treatmentsListElement.append(template.content.firstChild)
      })
  }
}
