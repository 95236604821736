import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  treatmentSelect (event) {
    const treatmentId = event.target.value

    Rails.ajax({
      url: `/treatments/${treatmentId}`,
      type: 'GET',
      success: (data) => {
        document.getElementById('form_time_period').value = data.default_time
      }
    })
  }

  roomSelect (event) {
    const roomId = event.target.value

    Rails.ajax({
      url: `/rooms/${roomId}`,
      type: 'GET',
      success: (data) => {
        document.getElementById('form_treatment_id').value = data.treatmentId
        this.setTreatment(data.treatmentId)
      }
    })
  }
}
