import TurboStreamController from './turbo_stream_controller'

export default class extends TurboStreamController {
  connect () {
    this.form = document.getElementById('appointment-form')
  }

  // Handle switch of recurring select element on the form
  recurringSelect (event) {
    const recurringSelectElement = document.getElementById('recurring-select')

    if (event.target.value === 'true') {
      recurringSelectElement.classList.remove('d-none')
    } else {
      recurringSelectElement.classList.add('d-none')
    }
  }

  submitEnd (event) {
    const responseStatus = event.detail.fetchResponse.response.status

    // Only hide if we get 200. We can get 202 if we need to render a confirmation page.
    if (responseStatus == 200) {
      document.dispatchEvent(new CustomEvent('modal:hide'))

      if (this.form.dataset.initiatedFrom === 'index') {
        window.location.reload(true)
      }
    }
  }
}
