import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  exportCSV (event) {
    if (event.detail.success) {
      const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      const headers = {
        'X-CSRF-Token': csrfToken
      }

      const exportType = document.getElementById('form_export_type').value
      const fromDate = document.getElementById('form_from_date').value
      const toDate = document.getElementById('form_to_date').value

      const url = new URL(
        `/payments/exports/download?export_type=${exportType}&start_date=${fromDate}&end_date=${toDate}`,
        window.location.href
      )

      fetch(url.toString(), { headers })
        .then((response) => response.blob())
        .then((data) => this.downloadBlob(data))

      document.dispatchEvent(new CustomEvent('modal:hide'))
    }
  }

  downloadBlob (blob) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')
    link.style.display = 'none'
    // Set link's href to point to the Blob URL
    link.href = blobUrl
    link.download = name
    // Append link to the body
    document.body.appendChild(link)
    // Dispatch click event on the link
    link.click()
    // Remove link from body
    document.body.removeChild(link)
    // Release an existing object URL
    URL.revokeObjectURL(blobUrl)
  }
}
