import TurboStreamController from './turbo_stream_controller'

export default class extends TurboStreamController {
  connect () {
    this.screenLockModalElement = document.getElementById('screen-lock-modal')

    this.boundFocusPinElement = this.focusPinElement.bind(this)
    this.screenLockModalElement.addEventListener('shown.bs.modal', this.boundFocusPinElement)

    this.focusPinElement()
  }

  disconnect () {
    this.screenLockModalElement.removeEventListener('shown.bs.modal', this.boundFocusPinElement)
  }

  focusPinElement () {
    const formPinElement = document.getElementById('form_pin')

    if (formPinElement) {
      formPinElement.focus()
    }
  }

  submitEnd (event) {
    if (event.detail.success) {
      document.dispatchEvent(new CustomEvent('screen_lock:hide'))
    }
  }
}
