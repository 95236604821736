import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  putReload (event) {
    event.preventDefault()

    Rails.ajax({
      type: 'PUT',
      url: event.target.href,
      success: (data) => {
        window.location.reload(true)
      }
    })
  }

  deleteReload (event) {
    event.preventDefault()

    let response = true

    if (event.target.dataset.withConfirm === 'true') {
      response = confirm(event.target.dataset.text)
    }

    if (response === true) {
      Rails.ajax({
        type: 'DELETE',
        url: event.target.href,
        success: (data) => {
          if (event.target.dataset.redirectTo) {
            window.location.replace(event.target.dataset.redirectTo)
          } else {
            window.location.reload(true)
          }
        }
      })
    }
  }
}
