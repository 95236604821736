import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.boundAutocomplete = this.autocomplete.bind(this)

    document.addEventListener('autocomplete.change', this.boundAutocomplete)
  }

  disconnect () {
    document.removeEventListener('autocomplete.change', this.boundAutocomplete)
  }

  autocomplete (event) {
    if (event.target.classList.contains('form_user_id')) {
      this.userSelect(event.detail.value)
    }
  }

  userSelect (userId) {
    const newUserElement = document.getElementById('new-user')

    if (newUserElement === null) {
      return
    }

    if (userId !== 'new' && !newUserElement.classList.contains('d-none')) {
      newUserElement.classList.add('d-none')

      return
    }

    if (userId === 'new') {
      newUserElement.classList.remove('d-none')
    }
  }
}
