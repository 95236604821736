import TurboStreamController from './turbo_stream_controller'

export default class extends TurboStreamController {
  connect () {
    document.dispatchEvent(new CustomEvent('screen_lock:set_timer'))
  }

  disconnect () {
    document.dispatchEvent(new CustomEvent('screen_lock:clear_timer'))
  }

  lock (event) {
    event.preventDefault()

    document.dispatchEvent(new CustomEvent('screen_lock:show'))
  }
}
