import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.boundAutocomplete = this.autocomplete.bind(this)

    document.addEventListener('autocomplete.change', this.boundAutocomplete)
  }

  disconnect () {
    document.removeEventListener('autocomplete.change', this.boundAutocomplete)
  }

  autocomplete (event) {
    if (event.target.classList.contains('form_rooms')) {
      this.addToList(event.detail)

      document.getElementById('form_rooms_search').value = ''
      document.getElementById('form_rooms').value = ''
    }
  }

  listElementUrl (detail) {
    return new URL(`/rooms/${detail.value}/list_element`, window.location.href)
  }

  addToList (detail) {
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrfToken
    }

    const roomsListElement = document.getElementById('rooms-list')
    const roomsChanged = document.getElementById('form_rooms_changed')
    const url = this.listElementUrl(detail)

    if (roomsChanged !== null) {
      roomsChanged.value = 1
    }

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        const template = document.createElement('template')
        template.innerHTML = html
        roomsListElement.append(template.content.firstChild)
      })
  }
}
