import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  amountChanged (event) {
    if (event.target.dataset.new !== 'true') {
      return
    }

    const paymentTypeValue = document.getElementById('form_payment_type').value
    const amountPaidElement = document.getElementById('form_amount_paid')
    const copyAmountTypes = ['card', 'cash']

    if (copyAmountTypes.includes(paymentTypeValue)) {
      amountPaidElement.value = event.target.value
    }
  }
}
