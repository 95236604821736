import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect () {
    this.sortable = Sortable.create(
      this.element,
      {
        animation: 150,
        onSort: function (evt) {
          const treatmentsChanged = document.getElementById('form_treatments_changed')
          treatmentsChanged.value = 1
        }
      }
    )
  }

  remove (event) {
    const treatmentsChanged = document.getElementById('form_treatments_changed')

    // treatmentsChanged may not exist if we are creating a new appointment
    if (treatmentsChanged) {
      treatmentsChanged.value = 1
    }

    event.target.parentElement.remove()
  }
}
