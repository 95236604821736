import { Application } from '@hotwired/stimulus'
import { Autocomplete } from 'stimulus-autocomplete'
import consumer from '../channels/consumer'

const application = Application.start()

// Configure Stimulus development experience
application.debug = true

application.consumer = consumer

// Register autocomplete
application.register('autocomplete', Autocomplete)

window.Stimulus = application

export { application }
