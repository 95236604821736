import Rails from '@rails/ujs'
import { Calendar } from '@fullcalendar/core'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import TurboStreamController from './turbo_stream_controller'

export default class extends TurboStreamController {
  static targets = ['calendar']

  connect () {
    this.initializeCalendar()

    this.eventsCalendarChannel = this.application.consumer.subscriptions.create({ channel: 'EventsCalendarChannel' }, {
      received: (data) => {
        switch (data.action) {
          case 'refresh':
            this.refresh()
            break
        }
      }
    })
  }

  disconnect () {
    this.eventsCalendarChannel.unsubscribe()
    this.calendar.destroy()
  }

  initializeCalendar () {
    const _this = this

    this.calendar = new Calendar(this.calendarTarget, {
      schedulerLicenseKey: FULLCALENDAR_LICENSE_KEY,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: ''
      },
      titleFormat: {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long'
      },
      events: '/events/calendar.json',
      resources: '/rooms.json',
      resourceOrder: 'title',
      editable: true,
      navLinks: true,
      nowIndicator: true,
      expandRows: true,
      contentHeight: 'auto',
      initialDate: this.calendarTarget.dataset.calendarDate,
      initialView: 'resourceTimeGridDay',
      slotMinTime: '07:00:00',
      slotMaxTime: '22:00:00',
      slotDuration: '00:30:00',
      slotLabelInterval: { minutes: '30' },
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: false,
        hour12: false
      },
      displayEventTime: false,
      allDaySlot: false,
      longPressDelay: 3000, // 3 seconds for drag
      plugins: [resourceTimeGridPlugin, interactionPlugin],
      dateClick: (info) => { _this.dateClick(info) },
      eventClick: (info) => { _this.eventClick(info) },
      eventDrop: (info) => { _this.eventDrop(info) },
      eventResize: (info) => { _this.eventResize(info) }
    })

    this.calendar.render()
  }

  dateClick (info) {
    info.jsEvent.preventDefault()

    const { id } = info.resource.toJSON()
    const date = info.dateStr

    const url = new URL(`${window.location.origin}/events/new`)
    url.searchParams.append('from', 'calendar')
    url.searchParams.append('form[room_id]', id)
    url.searchParams.append('form[start_time]', date)

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  eventClick (info) {
    info.jsEvent.preventDefault()

    const url = `/events/${info.event.id}/edit?from=calendar`

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  eventResize (info) {
    const data = {
      from: 'calendar',
      'form[start_time]': info.event.start,
      'form[end_time]': info.event.end
    }

    Rails.ajax({
      type: 'PUT',
      url: `/events/${info.event.id}/calendar_move`,
      data: new URLSearchParams(data).toString()
    })
  }

  eventDrop (info) {
    if (info.event.extendedProps.recurring) {
      const url = new URL(`${window.location.origin}/events/${info.event.id}/calendar_move/step_one`)
      url.searchParams.append('from', 'calendar')
      url.searchParams.append('form[start_time]', info.event.start)
      url.searchParams.append('form[end_time]', info.event.end)

      if (info.newResource) {
        const room = info.newResource.toJSON()

        url.searchParams.append('form[room_id]', room.id)
      }

      this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
    } else {
      const data = {
        from: 'calendar',
        'form[start_time]': info.event.start,
        'form[end_time]': info.event.end
      }

      if (info.newResource) {
        const room = info.newResource.toJSON()

        data['form[room_id]'] = room.id
      }

      Rails.ajax({
        type: 'PUT',
        url: `/events/${info.event.id}/calendar_move`,
        data: new URLSearchParams(data).toString()
      })
    }
  }

  refresh () {
    this.calendar.refetchEvents()
  }
}
